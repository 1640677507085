<template>
  <div class="gallery-image">
    <label class="form-control-label"> {{ label }} </label>
    <div class="card-img pb-4">
      <div
        v-for="(attachement, key) in attachements"
        :key="key"
        class="gallery-image-container mb-2 card-img"
      >
        <img :src="`${attachement}`" class="gallery-image" v-if="attachement.includes('png') || attachement.includes('jpg') || attachement.includes('jpeg') || attachement.includes('gif') || attachement.includes('svg')" />
        <img :src="`/img/icons/files/text.png`" class="gallery-image" v-else-if="attachement.includes('txt')" />
        <img :src="`/img/icons/files/pdf.png`" class="gallery-image" v-else-if="attachement.includes('pdf')" />
        <img :src="`/img/icons/files/doc.png`" class="gallery-image" v-else-if="attachement.includes('docx') || attachement.includes('doc')" />
        <img :src="`/img/icons/files/xls-file.png`" class="gallery-image" v-else-if="attachement.includes('xlsx') || attachement.includes('xlsm') || attachement.includes('xlsb') || attachement.includes('xltx') || attachement.includes('csv')" />
        <img :src="`/img/icons/files/default.png`" class="gallery-image" v-else />
        <base-button
          type="button"
          class="gallery-image-remove-btn btn btn-sm btn-danger"
          @click="removeImage(key)"
        >
          <span>
            <i class="fal fa-trash-alt"></i>
          </span>
        </base-button>
      </div>

      <base-button
        type="button"
        class="btn btn-sm mr-2 mb-2 gallery-add-image-btn"
      >
        <label for="imageInputAttachements">
          <i v-if="loading" class="fas fa-spinner fa-spin"></i>
          <i v-else class="far fa-plus"></i>
        </label>
        <input
          id="imageInputAttachements"
          ref="imageInput"
          type="file"
          style="display: none"
          @input="onFileChange"
        />
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "attachements-selector",

  props: {
    defaultAttachements: {
      type: Array,
      default: null,
      description: "Default attachements to diplay, should be model value",
    },
    label: {
      type: String,
      default: null,
      description: "Label of the field",
    },
    ressource_name: {
      type: String,
      default: null,
      description:
        "Ressource name to be uploaded with, must be allowed in the api",
    },
    ressouce_id: {
      type: String,
      default: null,
      description:
        "Ressource id to be uploaded with, must be allowed in the api",
    },
    field: {
      type: String,
      default: null,
      description:
        "Ressource field to be uploaded with, must be allowed in the api",
    },
  },

  setup() {},

  data() {
    return {
      attachements: [],
      loading: false,
    };
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadFile(files[0]);
    },

    async uploadFile(file) {
      this.loading = true;
      if (file) {
        try {
          await this.$store.dispatch("files/upload", {
            image: file,
            ressource_name: this.ressource_name,
            ressource_id: this.ressource_id,
            field: this.field,
          });
          let image_url = await this.$store.getters["files/url"];
          this.attachements = [...this.attachements, image_url];
          this.$emit("attachementsChanged", this.attachements);
          this.$refs.imageInput.value = null;
          this.loading = false;
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
        }
      }
    },

    removeImage(key) {
      this.attachements = this.attachements.filter((_, idx) => key !== idx);
      this.$emit("attachementsChanged", this.attachements);
    },
  },
  created(){
    this.attachements = this.defaultAttachements;
  },

  watch: {
    /*defaultAttachements(defaultAttachements) {
      console.log(defaultAttachements);
      this.attachements = defaultAttachements;
    },*/
    defaultAttachements: {
      handler(val) {
        console.log(val);
        if (val) {
          this.attachements = val;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-image {
  width: 100%;
  .card-img {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0 0 0;
    .gallery-image-container {
      width: 120px;
      height: 120px;
      border: 1px solid #ccc;
      display: flex;
      position: relative;
      margin: 0 20px 20px 0;
      &:hover {
        .gallery-image-remove-btn {
          opacity: 1;
          visibility: visible;
        }
      }
      img {
        object-fit: contain;
      }
      .gallery-image-remove-btn {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: rgb(255, 0, 0, 0.6);
        margin: 0 !important;
        border: none;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        i {
          font-size: 30px;
          color: #fff;
        }
      }
    }
    .gallery-image {
      width: 100%;
      height: 100%;
    }
    .gallery-add-image-btn {
      width: 120px;
      height: 120px;
      margin: 0 !important;
      padding: 0;
      background-color: #000;
      box-shadow: none;
      i {
        font-size: 40px;
        color: #0070ff;
      }
    }
    .gallery-add-image-btn label {
      width: 100%;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
}
</style>
